import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { 
    Grid, Box,
    Button, TextField, Radio,
    RadioGroup, FormLabel, FormControlLabel,
    Select, InputLabel, FormGroup,
    ImageList, ImageListItem,
    MenuItem, FormControl, Dialog,
    DialogActions, DialogContent, DialogContentText,
    DialogTitle
 } from "@mui/material";
import { AiOutlineSave, AiOutlineDelete, AiOutlineCheck } from "react-icons/ai";


function Form(){

    const { id } = useParams();
    const [data, setData] = useState([]);
    
    const [noSiri, setNoSiri] = useState("");
    const [ic, setIc] = useState("");
    const [icLama, setIcLama] = useState("");
    const [nama, setNama] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [jantina, setJantina] = useState("");
    const [tahunLahir, setTahunLahir] = useState("");
    const [noRumah, setNoRumah] = useState("");
    const [kodLokaliti, setKodLokaliti] = useState("");
    const [namaLokaliti, setNamaLokaliti] = useState("");
    const [namaParlimen, setNamaParlimen] = useState("");
    const [namaDM, setNamaDM] = useState("");
    const [namaDUN, setNamaDUN] = useState("");
    const [negeri, setNegeri] = useState("");
    const [bantuan, setBantuan] = useState("");
    const [tindakan, setTindakan] = useState("");
    const [images, setImages] = useState([]);
    
    //store image url
    const [imagesUrl, setImagesUrl] = useState([]);

    //strore dr api
    const [imagesAPI, setImagesApi] = useState([]);


    const navigate = useNavigate();
    
    const api_url = process.env.REACT_APP_API_URL;
    const get_api = api_url + "/api/one_data?id=" + id;

    //GET DATA
    const getData = async () => {
        const res = await axios.get(
            get_api,
        );

        const data = res.data;
        console.log(data);

        setNoSiri(data.noSiri);
        setIc(data.ic);
        setIcLama(data.icLama);
        setNama(data.nama);
        setEmail(data.email);
        setPhoneNo(data.phoneNo);
        setJantina(data.jantina);
        setTahunLahir(data.tahunLahir);
        setNoRumah(data.noRumah);
        setKodLokaliti(data.kodLokaliti);
        setNamaLokaliti(data.namaLokaliti);
        setNamaParlimen(data.namaParlimen);
        setNamaDM(data.namaDM);
        setNamaDUN(data.namaDun);
        setNegeri(data.negeri);
        setBantuan(data.bantuan);
        setTindakan(data.tindakan);
        //setImagesApi(data.img);
        setImages(data.img);
        
    }
    useEffect(() => {
        getData();
    }, []);

    // console.log(images);

    //get img url from api
    // const imgApiUrl = images.map((url) => {
    //     return(
    //         <ImageListItem key={url.img}>
    //             <img 
    //                 src={url} 
    //                 alt="" 
    //                 key={url} 

    //             />
    //         </ImageListItem>
    //     );
    // })

    //send ke api
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        Array.from(images).forEach(i => {
            formData.append("img", i);
        });

        formData.append("noSiri", noSiri);
        formData.append("ic", ic);
        formData.append("icLama", icLama);
        formData.append("email", email);
        formData.append("phoneNo", phoneNo);
        formData.append("nama", nama);
        formData.append("jantina", jantina);
        formData.append("noRumah", noRumah);
        formData.append("kodLokaliti", kodLokaliti);
        formData.append("namaLokaliti", namaLokaliti);
        formData.append("namaParlimen", namaParlimen);
        formData.append("namaDM", namaDM);
        formData.append("namaDun", namaDUN);
        formData.append("negeri", negeri);
        formData.append("tahunLahir", tahunLahir);
        formData.append("tindakan", tindakan);
        //formData.append("img", images);
        formData.append("bantuan", bantuan);

        // console.log(images);


        try{
            if(id == 0){
                axios.post(
                    api_url + "/api/main",
                    formData,
                    {
                        headers:{
                            'Accept': 'application/json',
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then(res => {
                    console.log('Success');
                }).catch(err => 
                    console.log(err)    
                )
            }
            else{
                axios.put(
                    api_url + "/api/one_data?id=" + id,
                    formData,
                    {
                        headers:{
                            'Accept': 'application/json',
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then(res => {
                    console.log('Success');
                }).catch(err => 
                    console.log(err)    
                )
            }

            navigate('/home');

        }
        catch(err){
            console.log(err);
        }
    }

    //image
    const handleMultipleImages =(e)=>{
        const selectedFIles = [];
        const selectedFIlesUrl = [];
        const targetFiles =e.target.files;
        const targetFilesObject= [...targetFiles];
        //const targetFilesObjectUrl = [...targetFiles];
        
        const saveImages =  targetFilesObject.map((file)=>{
           return selectedFIles.push(file)
        });
        const saveUrl = targetFilesObject.map((file)=>{
            return selectedFIlesUrl.push(URL.createObjectURL(file))
        });
 
        setImages(selectedFIles);
        setImagesUrl(selectedFIlesUrl);
        console.log(selectedFIles);
        console.log(selectedFIlesUrl);
        console.log(images);
    }

    //handle value
    const handleRadioButton = (e) => {
        setJantina(e.target.value); 
    };
    const handleDropDown = (e) => {
        setTahunLahir(e.target.value);
    }
    const handleRadioButton2 = (e) => {
        setTindakan(e.target.value);
    }

    //year
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];
  
    for (var i = max; i >= min; i--) {
      years.push(i)
    }

    const menuItemYears = years.map(i => (
        <MenuItem value={i}>{i}</MenuItem>
    ));

    //dialog for save
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return(
        <Box sx={{flexGrow: 1}} padding={5}>
            {
                id == 0 ?
                <h1>Add New Data</h1> :
                <h1>Update Data</h1>
            }
            <Box component="form" encType="multipart/form-data" onSubmit={handleSubmit} noValidate>
                <Grid className="personal" container spacing={2} paddingTop={5}>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="No Siri"
                            value={noSiri}
                            onChange={(e) => setNoSiri(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="NOIC Baru"
                            value={ic}
                            onChange={(e) => setIc(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="NOIC Lama"
                            value={icLama}
                            onChange={(e) => setIcLama(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama"
                            value={nama}
                            onChange={(e) => setNama(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nombor Tel."
                            value={phoneNo}
                            onChange={(e) => setPhoneNo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel>Jantina</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="lelaki" control={<Radio />} onChange={handleRadioButton} label="Lelaki" />
                            <FormControlLabel value="perempuan" control={<Radio />} onChange={handleRadioButton} label="Perempuan" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel>Tahun Lahir</InputLabel>
                            <Select
                                value={tahunLahir}
                                label="Tahun Lahir"
                                onChange={handleDropDown}
                            >
                                {menuItemYears}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className="place" container spacing={2} paddingTop={5}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="No Rumah"
                            value={noRumah}
                            onChange={(e) => setNoRumah(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Kod Lokaliti"
                            value={kodLokaliti}
                            onChange={(e) => setKodLokaliti(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama Lokaliti"
                            value={namaLokaliti}
                            onChange={(e) => setNamaLokaliti(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama Parlimen"
                            value={namaParlimen}
                            onChange={(e) => setNamaParlimen(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama DM"
                            value={namaDM}
                            onChange={(e) => setNamaDM(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama DUN"
                            value={namaDUN}
                            onChange={(e) => setNamaDUN(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Negeri"
                            value={negeri}
                            onChange={(e) => setNegeri(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid className="report" container spacing={2} paddingTop={5}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Bantuan"
                            multiline
                            row={5}
                            maxRows={10}
                            value={bantuan}
                            onChange={(e) => setBantuan(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Tindakan
                        </InputLabel>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="Notis Maklumat Terima" control={<Radio />} onChange={handleRadioButton2} label="Notis Maklumat Terima" />
                            <FormControlLabel value="Sedang Proses" control={<Radio />} onChange={handleRadioButton2} label="Sedang Proses" />
                            <FormControlLabel value="Telah Selesai" control={<Radio />} onChange={handleRadioButton2} label="Telah Selesai" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Gambar Bukti
                        </InputLabel>
                        <input type="file" id="file" multiple onChange={handleMultipleImages}></input>
                        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                            {/* {renderPhotos(selectedFiles)} */}
                            {
                                imagesUrl.map((url) => {
                                    return(
                                        <ImageListItem key={url.img}>
                                            <img 
                                                src={url} 
                                                alt="" 
                                                key={url} 

                                            />
                                        </ImageListItem>
                                    );
                                })
                            }
                        </ImageList>
                        {/* display img from api */}
                        {/* <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                            {
                                images.map((url) => {
                                    console.log(url);
                                    return(
                                        <ImageListItem key={url}>
                                            <img 
                                                src={URL.createObjectURL(url)} 
                                                alt="" 
                                                key={url} 

                                            />
                                        </ImageListItem>
                                    );
                                })
                            }
                        </ImageList> */}
                    </Grid>
                </Grid>
                <Grid className="action" container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button 
                            variant="contained" 
                            startIcon={<AiOutlineSave />}
                            onClick={handleClickOpen}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                            {"The Data has been saved"}
                            </DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Your new data has been added into database
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    variant="contained" 
                                    color="success"
                                    onClick={handleClose}  
                                    type="submit"
                                    startIcon={<AiOutlineCheck />}
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}


export default Form;