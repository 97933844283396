import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { 
    Avatar, Button, CssBaseline,
    TextField, FormControlLabel, Checkbox,
    Link, Grid, Box,
    Typography, Container, Alert
 } from "@mui/material";
 import { AiOutlineUser } from "react-icons/ai";

function Login_form() {

    //send to REST API
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const api_url = process.env.REACT_APP_API_URL;
    const url = api_url + "/user/signin";
    
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
          'email': email,
          'password': password
        };

        try{
            axios.post(
              api_url + "/user/signin",
              data,
              {
                  headers:{
                      //'Accept': 'application/json',
                      "Content-Type": "application/x-www-form-urlencoded"
                  }
              }
            ).then(res => {
                // console.log(res.data.data);
                //navigate('/home');
                if(res.data.data){
                  
                  navigate('/home');
                }
                else if(res.data.error){
                  return(
                    <Alert severity="error">Wrong Password!</Alert>
                  );
                }
                else{
                  navigate('/signup');
                }

            }).catch(err => 
                console.log(err)    
            )
            //axios(options);
            // console.log(email);
            // console.log(password);
        }
        catch(err){
            console.log(err);
        }
    }


    return(
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AiOutlineUser />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forget_password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    );
}


export default Login_form;