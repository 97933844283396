import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { 
    Grid, Box,
    Button, TextField, Radio,
    RadioGroup, FormLabel, FormControlLabel,
    Select, InputLabel, FormGroup,
    ImageList, ImageListItem,
    MenuItem, FormControl, Dialog,
    DialogActions, DialogContent, DialogContentText,
    DialogTitle
 } from "@mui/material";
import { AiOutlineSave, AiOutlineDelete, AiOutlineCheck } from "react-icons/ai";

function TestForm(){


    const { id } = useParams();
    const [data, setData] = useState([]);
    
    const [noSiri, setNoSiri] = useState("");
    const [ic, setIc] = useState("");
    const [icLama, setIcLama] = useState("");
    const [nama, setNama] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [jantina, setJantina] = useState("");
    const [tahunLahir, setTahunLahir] = useState("");
    const [noRumah, setNoRumah] = useState("");
    const [kodLokaliti, setKodLokaliti] = useState("");
    const [namaLokaliti, setNamaLokaliti] = useState("");
    const [namaParlimen, setNamaParlimen] = useState("");
    const [namaDM, setNamaDM] = useState("");
    const [namaDUN, setNamaDUN] = useState("");
    const [negeri, setNegeri] = useState("");
    const [bantuan, setBantuan] = useState("");
    const [tindakan, setTindakan] = useState("");
    const [images, setImages] = useState([]);


    //handle value
    const handleRadioButton = (e) => {
        setJantina(e.target.value); 
    };
    const handleDropDown = (e) => {
        setTahunLahir(e.target.value);
    }
    const handleRadioButton2 = (e) => {
        setTindakan(e.target.value);
    }


    //year
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];
  
    for (var i = max; i >= min; i--) {
      years.push(i)
    }

    const menuItemYears = years.map(i => (
        <MenuItem value={i}>{i}</MenuItem>
    ));



    //ejas lake

    const api_url = process.env.REACT_APP_API_URL + '/api/main';

    //console.log(api_url);

    const handleSubmit = async (e) =>{
        e.preventDefault();
        const formData = new FormData();
        formData.append("noSiri", noSiri);
        formData.append("ic", ic);
        formData.append("icLama", icLama);
        formData.append("email", email);
        formData.append("phoneNo", phoneNo);
        formData.append("nama", nama);
        formData.append("jantina", jantina);
        formData.append("noRumah", noRumah);
        formData.append("kodLokaliti", kodLokaliti);
        formData.append("namaLokaliti", namaLokaliti);
        formData.append("namaParlimen", namaParlimen);
        formData.append("namaDM", namaDM);
        formData.append("namaDun", namaDUN);
        formData.append("negeri", negeri);
        formData.append("tahunLahir", tahunLahir);
        formData.append("tindakan", tindakan);
        formData.append("bantuan", bantuan);

        for(const key of Object.keys(images)){
            formData.append("img", images[key]);
        }

        try{
            axios.post(
                api_url,
                formData,
                {headers: {
                    "Content-type": "multipart/form-data"
                }}
            );
        }
        catch(err){
            console.log(err);
        }
    }

    const handleMultipleImages = (e) => {

    }    




    //design
    return(
        <Box sx={{flexGrow: 1}} padding={5}>
            {
                id == 0 ?
                <h1>Add New Data</h1> :
                <h1>Update Data {id}</h1>
            }
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <Grid className="personal" container spacing={2} paddingTop={5}>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="No Siri"
                            value={noSiri}
                            onChange={(e) => setNoSiri(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="NOIC Baru"
                            value={ic}
                            onChange={(e) => setIc(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="NOIC Lama"
                            value={icLama}
                            onChange={(e) => setIcLama(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama"
                            value={nama}
                            onChange={(e) => setNama(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nombor Tel."
                            value={phoneNo}
                            onChange={(e) => setPhoneNo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel>Jantina</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="lelaki" control={<Radio />} onChange={handleRadioButton} label="Lelaki" />
                            <FormControlLabel value="perempuan" control={<Radio />} onChange={handleRadioButton} label="Perempuan" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel>Tahun Lahir</InputLabel>
                            <Select
                                value={tahunLahir}
                                label="Tahun Lahir"
                                onChange={handleDropDown}
                            >
                                {menuItemYears}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className="place" container spacing={2} paddingTop={5}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="No Rumah"
                            value={noRumah}
                            onChange={(e) => setNoRumah(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Kod Lokaliti"
                            value={kodLokaliti}
                            onChange={(e) => setKodLokaliti(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama Lokaliti"
                            value={namaLokaliti}
                            onChange={(e) => setNamaLokaliti(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama Parlimen"
                            value={namaParlimen}
                            onChange={(e) => setNamaParlimen(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama DM"
                            value={namaDM}
                            onChange={(e) => setNamaDM(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama DUN"
                            value={namaDUN}
                            onChange={(e) => setNamaDUN(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Negeri"
                            value={negeri}
                            onChange={(e) => setNegeri(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid className="report" container spacing={2} paddingTop={5}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Bantuan"
                            multiline
                            row={5}
                            maxRows={10}
                            value={bantuan}
                            onChange={(e) => setBantuan(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Tindakan
                        </InputLabel>
                        <FormGroup>
                            <FormControlLabel value="Notis Maklumat Terima" control={<Radio />} onChange={handleRadioButton2} label="Notis Maklumat Terima" />
                            <FormControlLabel value="Sedang Proses" control={<Radio />} onChange={handleRadioButton2} label="Sedang Proses" />
                            <FormControlLabel value="Telah Selesai" control={<Radio />} onChange={handleRadioButton2} label="Telah Selesai" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Gambar Bukti
                        </InputLabel>
                        <input type="file" id="file" multiple onChange={handleMultipleImages}></input>
                        {/* <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}> */}
                            {/* {renderPhotos(selectedFiles)} */}
                            {/* {
                                images.map((url) => {
                                    return(
                                        <ImageListItem key={url.img}>
                                            <img src={url} alt="" key={url} />
                                        </ImageListItem>
                                    );
                                })
                            }
                        </ImageList> */}
                    </Grid>
                </Grid>
                <Grid className="action" container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button 
                            variant="contained" 
                            startIcon={<AiOutlineSave />}
                            type="submit"
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            color="error" 
                            startIcon={<AiOutlineDelete />}
                            type="reset"
                        >
                            Clear / Reset
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default TestForm;


            // let res = await fetch(api_url + "/api/main", reqOpt);
            // let resJson = await res.json();
            //setData(prev => [...prev, resJson]);
            //console.log(resJson);
            // if (res.status === 200) {
            //     setNoSiri("");
            //     setIc("");
            //     setIcLama("");
            //     setNama("");
            //     setEmail("");
            //     setPhoneNo("");
            //     setJantina("");
            //     setTahunLahir("");
            //     setNoRumah("");
            //     setKodLokaliti("");
            //     setNamaLokaliti("");
            //     setNamaParlimen("");
            //     setNamaDM("");
            //     setNamaDUN("");
            //     setNegeri("");
            //     setBantuan("");
            //     setTindakan("");
            //     setImages([]);
            // }
            //console.log(sendData);

                   // setImages(selectedFIles);
        // targetFilesObject.forEach(file => {

        //     selectedFIles.push({
        //         data: file,
        //         url: URL.createObjectURL(file)
        //     });
            
        // });
        
        // const sendData = {
        //     noSiri: noSiri,
        //     ic: ic,
        //     icLama: icLama,
        //     email: email,
        //     phoneNo: phoneNo,
        //     nama: nama,
        //     jantina: jantina,
        //     noRumah: noRumah,
        //     kodLokaliti: kodLokaliti,
        //     namaLokaliti: namaLokaliti,
        //     namaParlimen: namaParlimen,
        //     namaDM: namaDM,
        //     namaDun: namaDUN,
        //     negeri: negeri,
        //     tahunLahir: tahunLahir,
        //     tindakan: tindakan,
        //     img: JSON.stringify(images),
        //     bantuan: bantuan
        // };

        // console.log(sendData);
                // Object.values(images).forEach(file => {
        //     formData.append("img", file);
        // });
        // for(var i = 0; i < images.length; i++) {
        //     formData.append("img", images[i]);
        // }
        // for(const key of Object.keys(images)){
        //     formData.append("img", images[key]);
        // }