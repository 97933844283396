//require('dotenv').config();
import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { 
    Grid, Box, Paper, 
    Table, TableBody, TableCell, 
    TableContainer, TableHead, TablePagination,
    TableRow, TableFooter, Button
 } from "@mui/material";
import { 
    AiOutlineFileAdd, AiOutlineEdit, 
    AiOutlineDelete, AiOutlineDownload } from "react-icons/ai";
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';


function TablePaginationActions(props) {

    //page for table
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
}
  
TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


function Home() {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [data, setData] = useState([]);

    //Fetch API
    const api_url = process.env.REACT_APP_API_URL;
    //console.log(api_url + "/api/main");
    const fetchData = () => {
        return fetch(api_url + "/api/main")
                .then((res) => res.json())
                .then((data) => setData(data))
                .catch((err) => 
                    console.log(err)
                );
    }
    useEffect(() => {
        fetchData();
    }, []);

    const navigate = useNavigate();

    // console.log(data);

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    //delete
    async function handleDelete(id) {

        const url = id != 0 ?
                    api_url + "/api/one_data?id=" + id :
                    api_url + "/api/main"

        await fetch(
            url,
            {
                method: 'DELETE'
            }
        );
        //console.log('Success delete ' + id);
        navigate('/home');
    }

    //excel
    function handleDownloadExcel(){
        fetch(
            api_url + "/api/excel_file",
            {
                method: "GET"
            }
        );
    }


    return(
        <Box sx={{flexGrow: 1}} padding={5}>
            <Grid container justifyContent={"space-between"} paddingBottom={5}>
                <Grid item>
                    <h1>Home</h1>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained" 
                        startIcon={<AiOutlineFileAdd size={30}/>}
                        href="/form/0"
                    >
                        Add New
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>No Siri</TableCell>
                                    <TableCell align="center">Nama</TableCell>
                                    <TableCell align="center">Ic</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {

                                }
                                {(rowsPerPage > 0
                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : data
                                ).map((d) => (
                                    <TableRow 
                                        key={d.noSiri}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row"> {d.noSiri} </TableCell>
                                        <TableCell align="center"> {d.nama} </TableCell>
                                        <TableCell align="center"> {d.ic} </TableCell>
                                        <TableCell>
                                            <Grid container spacing={5} justifyContent="flex-end">
                                            <Grid item>
                                                    <Button href={"/view/" + d._id} variant="contained" color="primary">
                                                        View
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button href={"/form/" + d._id} variant="contained" color="success" startIcon={<AiOutlineEdit />}>
                                                        Edit
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button onClick={ () => handleDelete(d._id) } variant="contained" color="error" startIcon={<AiOutlineDelete />}>
                                                        Delete
                                                    </Button>   
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                        'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end" paddingTop={5}>
                <Grid item>
                    <Button href={api_url + "/api/excel_file"} variant="contained" startIcon={<AiOutlineDownload/>}>
                         Download Excel
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={ () => handleDelete(0) } variant="contained" color="error" startIcon={<AiOutlineDelete />}>
                        Delete All
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}


export default Home;