import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { 
    Avatar, Button, CssBaseline,
    TextField, Link, Grid, Box,
    Typography, Container
 } from "@mui/material";
import { AiOutlineUser } from "react-icons/ai";

function Signup_form() {

    //upload to REST API
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const api_url = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const formData = new FormData();

        // formData.append("name", name);
        // formData.append("username", username);
        // formData.append("email", email);
        // formData.append("password", password);

        const data = {
            'name': name,
            'username': username,
            'email': email,
            'password': password
        };

        try{
            axios.post(
                api_url + "/user/signup",
                data,
                {
                    headers:{
                        //'Accept': 'application/json',
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            ).then(res => {
                // console.log('Success');
                navigate('/');
            }).catch(err => 
                console.log(err)    
            )
            // console.log(name);
            // console.log(username);
            // console.log(email);
            // console.log(password);
        }
        catch(err){
            console.log(err);
        }
    }


    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <AiOutlineUser />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                            name="name"
                            required
                            fullWidth
                            id="name"
                            label="Nama"
                            autoFocus
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            id="userName"
                            label="Username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2">
                            Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
      </Container>
    );
}


export default Signup_form;