import React from 'react';
import Header from './Header';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from '../pages/main_page';
import Form from '../pages/form';
import View_Data from '../pages/view_data';
//import Update_form from '../pages/update_form';
import Login_form from '../pages/login';
import ForgetPassowrd_form from '../pages/forget_password';
import Signup_form from '../pages/signup';
// import Footer from './components/Footer';

import TestForm from '../pages/test_form';

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path='/home' element={<Home/>}/>
        <Route path='/form/:id' element={<Form/>} />
        <Route path='/view/:id' element={<View_Data/>} />
        {/* <Route path='/form/:id' element={<Add_form/>} /> */}
        <Route path='/' element={<Login_form />} />
        <Route path='/forget_password' element={<ForgetPassowrd_form />} />
        <Route path='/signup' element={<Signup_form />} />

        <Route path='/test' element={<TestForm />} />
      </Routes>
    </Router>
  );
}

export default App;
