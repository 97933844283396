import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { 
    Grid, Box,
    Button, TextField
 } from "@mui/material";
import { AiOutlineEdit, AiOutlineDelete, } from "react-icons/ai";


function View_Data(){

    const { id } = useParams();
    const [data, setData] = useState([]);
    
    const [noSiri, setNoSiri] = useState("");
    const [ic, setIc] = useState("");
    const [icLama, setIcLama] = useState("");
    const [nama, setNama] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [jantina, setJantina] = useState("");
    const [tahunLahir, setTahunLahir] = useState("");
    const [noRumah, setNoRumah] = useState("");
    const [kodLokaliti, setKodLokaliti] = useState("");
    const [namaLokaliti, setNamaLokaliti] = useState("");
    const [namaParlimen, setNamaParlimen] = useState("");
    const [namaDM, setNamaDM] = useState("");
    const [namaDUN, setNamaDUN] = useState("");
    const [negeri, setNegeri] = useState("");
    const [bantuan, setBantuan] = useState("");
    const [tindakan, setTindakan] = useState("");
    const [images, setImages] = useState([]);
    
    //store image url
    const [imagesUrl, setImagesUrl] = useState([]);

    //strore dr api
    const [imagesAPI, setImagesApi] = useState([]);


    const navigate = useNavigate();
    
    const api_url = process.env.REACT_APP_API_URL;
    const get_api = api_url + "/api/one_data?id=" + id;

    //GET DATA
    const getData = async () => {
        const res = await axios.get(
            get_api,
        );

        const data = res.data;
        console.log(data);

        setNoSiri(data.noSiri);
        setIc(data.ic);
        setIcLama(data.icLama);
        setNama(data.nama);
        setEmail(data.email);
        setPhoneNo(data.phoneNo);
        setJantina(data.jantina);
        setTahunLahir(data.tahunLahir);
        setNoRumah(data.noRumah);
        setKodLokaliti(data.kodLokaliti);
        setNamaLokaliti(data.namaLokaliti);
        setNamaParlimen(data.namaParlimen);
        setNamaDM(data.namaDM);
        setNamaDUN(data.namaDun);
        setNegeri(data.negeri);
        setBantuan(data.bantuan);
        setTindakan(data.tindakan);
        //setImagesApi(data.img);
        setImages(data.img);
        
    }
    useEffect(() => {
        getData();
    }, []);


    async function handleDelete(id) {

        const url = id != 0 ?
                    api_url + "/api/one_data?id=" + id :
                    api_url + "/api/main"

        await fetch(
            url,
            {
                method: 'DELETE'
            }
        );
        // console.log('Success delete ' + id);
        navigate('/home');
    }


    return(
        <Box sx={{flexGrow: 1}} padding={5}>
            <h1>View Data</h1>
            <Box noValidate>
                <Grid className="personal" container spacing={2} paddingTop={5}>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="No Siri"
                            value={noSiri}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="NOIC Baru"
                            value={ic}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="NOIC Lama"
                            value={icLama}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama"
                            value={nama}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Email"
                            value={email}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nombor Tel."
                            value={phoneNo}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Jantina"
                            value={jantina}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Tahun Lahir"
                            value={tahunLahir}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid className="place" container spacing={2} paddingTop={5}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="No Rumah"
                            value={noRumah}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Kod Lokaliti"
                            value={kodLokaliti}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama Lokaliti"
                            value={namaLokaliti}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama Parlimen"
                            value={namaParlimen}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama DM"
                            value={namaDM}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nama DUN"
                            value={namaDUN}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Negeri"
                            value={negeri}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid className="report" container spacing={2} paddingTop={5}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Bantuan"
                            multiline
                            row={5}
                            maxRows={10}
                            value={bantuan}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Tindakan"
                            value={tindakan}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Gambar Bukti
                        </InputLabel>
                        <input type="file" id="file" multiple onChange={handleMultipleImages}></input>
                        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}> */}
                            {/* {renderPhotos(selectedFiles)} */}
                            {/* {
                                imagesUrl.map((url) => {
                                    return(
                                        <ImageListItem key={url.img}>
                                            <img 
                                                src={url} 
                                                alt="" 
                                                key={url} 

                                            />
                                        </ImageListItem>
                                    );
                                })
                            }
                        </ImageList> */}
                        {/* display img from api */}
                        {/* <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                            {
                                images.map((url) => {
                                    console.log(url);
                                    return(
                                        <ImageListItem key={url}>
                                            <img 
                                                src={URL.createObjectURL(url)} 
                                                alt="" 
                                                key={url} 

                                            />
                                        </ImageListItem>
                                    );
                                })
                            }
                        </ImageList> */}
                    {/* </Grid> */}
                </Grid>
                <Grid className="action" container spacing={2} justifyContent="flex-end" paddingTop={5}>
                    <Grid item>
                        <Button href={"/form/" + id} variant="contained" color="success" startIcon={<AiOutlineEdit />}>
                            Edit
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={ () => handleDelete(id) } variant="contained" color="error" startIcon={<AiOutlineEdit />}>
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}


export default View_Data;