import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { 
    Avatar, Button, CssBaseline,
    TextField, FormControlLabel, Checkbox,
    Link, Grid, Box,
    Typography, Container
 } from "@mui/material";
 import { AiOutlineUser } from "react-icons/ai";

function ForgetPassowrd_form() {

    //send to REST API
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const api_url = process.env.REACT_APP_API_URL;
    
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
          'email': email,
          'password': password
        };

        try{
            axios.post(
              api_url + "/user/forget_password",
              data,
              {
                  headers:{
                      //'Accept': 'application/json',
                      "Content-Type": "application/x-www-form-urlencoded"
                  }
              }
            ).then(res => {
                //console.log('Success');
                navigate('/');

            }).catch(err => 
                console.log(err)    
            )
            //axios(options);
            // console.log(email);
            // console.log(password);
        }
        catch(err){
            console.log(err);
        }
    }


    return(
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AiOutlineUser />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forget Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change new Password
            </Button>
          </Box>
        </Box>
      </Container>
    );
}


export default ForgetPassowrd_form;