import React from "react";
import { AiOutlineDashboard } from "react-icons/ai";

function Header() {
    return(
        <header>
            <h1>
                <AiOutlineDashboard size={30} />
                Ketari 
            </h1>
        </header>
    );
}

export default Header;

{/* <Nav>
        <NavMenu>
            <NavLink to="/" activeStyle>
                <AiOutlineHome color="black" size={40}/>
            </NavLink>
            <NavLink to="/form" activeStyle>
                <AiFillFileAdd color="black" size={40}/>
            </NavLink>
        </NavMenu>
    </Nav> */}